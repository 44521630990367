<template>
  <div class="flex flex-col items-center w-full">
    <div class="h-112 w-full banner bg-cover bg-center relative">
      <div class="bg-black opacity-50 w-full h-full z-0 inset-0"></div>
      <div class="w-full h-full text-white absolute inset-0
        flex flex-col items-center justify-center">
        <div class="flex flex-col max-w-6xl w-full">
          <h1 class="text-3xl md:text-4xl font-bold text-white px-8 pb-8">
            Symposium/Seminars
          </h1>
          <div class="py-3 px-8 flex">
            <a
              class="bg-red-600 px-4 py-2 text-white text-sm font-medium download
                focus:outline-none hover:bg-red-500 active:bg-red-700"
              :href="activityCalendarURL"
            >
              ACTIVITY CALENDAR
            </a>
          </div>
          <div class="pt-3 px-8 flex">
            <div
              @click="memberGoto($router, '/seminar_members')"
              class="bg-red-600 px-4 py-2 text-white text-sm font-medium
                focus:outline-none hover:bg-red-500 active:bg-red-700 cursor-pointer"
            >
              MEMBERS - PAST EVENTS MATERIALS
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="max-w-5xl w-full py-16">
      <div class="elevation-2 hover:elevation-6 flex flex-col">
        <div class="w-full flex h-32 border-b">
          <div class="w-1/2 symposium bg-cover bg-center"></div>
          <div class="w-1/2 flex items-center text-2xl font-bold pl-10">
            Symposium
          </div>
        </div>
        <div class="w-full">
          <div class="symposium_body bg-cover bg-center flex flex-col py-10">
            <h3 class="text-red-800 text-2xl font-bold self-center text-center pt-8 pb-4 px-8">
              {{ symposium.title }}
            </h3>
            <div class="px-10 py-2 text-gray-900 font-medium">
              {{ symposium.keytakeaway }}
            </div>
            <div class="px-10 py-4 text-red-800 font-bold text-xl">
              {{ symposium.date }}
            </div>
            <div class="flex px-10">
              <div class="font-bold mr-2 text-blue-500">Date & Location:</div>
              <div
                v-for="(v, loc) in symposium.datelocation"
                :key="loc"
                class="text-gray-900 mr-3 font-medium"
              >
                {{ v }}.
              </div>
            </div>
            <div
              class="flex px-10 pt-5 pb-8"
              v-if="symposium.brochure"
            >
              <div
                @click="showRequestModal(symposium)"
                v-if="!symposium.canDownload"
                class="bg-red-700 px-4 py-2 text-white font-medium focus:outline-none
                  elevation-2 hover:elevation-6 active:elevation-1
                  transition duration-150 ease-in-out cursor-pointer"
              >
                Request Brochure
              </div>
              <a
                :href="symposium.brochure"
                v-else
                class="bg-red-700 px-4 py-2 text-white font-medium focus:outline-none
                  elevation-2 hover:elevation-6 active:elevation-1
                  transition duration-150 ease-in-out cursor-pointer"
              >Download</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full seminar bg-fixed bg-cover bg-center">
      <div class="w-full flex flex-col items-center justify-center">
        <div class="max-w-5xl w-full flex flex-col py-16">
          <div class="w-full flex h-32 border-b bg-white">
            <div class="w-1/2 flex items-center justify-end text-2xl font-bold pr-10">
              Seminars
            </div>
            <div class="w-1/2 seminar_header bg-cover bg-center"></div>
          </div>

          <div class="py-10 bg-white">
            <div class="flex flex-wrap px-3 items-stretch">
              <div
                class="md:w-1/2 w-full px-2 py-3 min-h-0"
                v-for="seminar in seminars"
                :key="seminar.title"
              >
                <div class="seminar_body bg-cover bg-center elevation-2 hover:elevation-6
                    h-full flex flex-col">
                  <h3
                    class="px-8 text-red-800 text-xl font-bold border-b py-6 flex items-center"
                    style="min-height: 6.5rem"
                  >
                    {{ seminar.title }}
                  </h3>
                  <div class="bg-white opacity-90 py-3 h-full">
                    <div
                      class="px-8 py-3 text-black"
                      v-if="seminar.keytakeaway"
                    >
                      {{ seminar.keytakeaway }}
                    </div>
                    <h4
                      class="px-8 pt-3 font-semibold text-red-800"
                      v-if="seminar.skill && seminar.skill.length > 0"
                    >
                      Skills to learn
                    </h4>
                    <div
                      class="px-8 pb-3 text-red-800"
                      v-if="seminar.skill && seminar.skill.length > 0"
                    >
                      {{ seminar.skill }}
                    </div>
                    <div class="px-8 py-3 text-red-800 font-bold">
                      {{ seminar.date }}
                    </div>
                    <div class="flex flex-wrap px-8">
                      <div class="font-bold mr-2 text-blue-500">
                        Date & Location:
                      </div>
                      <div
                        v-for="(v, loc) in seminar.datelocation"
                        :key="loc"
                        class="text-black mr-3"
                      >
                        {{ v }}.
                      </div>
                    </div>
                    <div
                      class="flex px-8 py-6"
                      v-if="seminar.brochure"
                    >
                      <div
                        @click="showRequestModal(seminar)"
                        v-if="!seminar.canDownload"
                        class="bg-red-700 px-4 py-2 text-white text-sm font-medium
                          focus:outline-none elevation-2 hover:elevation-6 active:elevation-1
                          transition duration-150 ease-in-out cursor-pointer"
                      >
                        Request Brochure
                      </div>
                      <a
                        :href="seminar.brochure"
                        v-else
                        class="bg-red-700 px-4 py-2 text-white text-sm font-medium
                          focus:outline-none elevation-2 hover:elevation-6 active:elevation-1
                          transition duration-150 ease-in-out cursor-pointer"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RequestBrochureModal
      v-model="requestModalVisible"
      :request.sync="brochureRequest"
      @request-sent="requestSent"
    />
    <Alert
      bottom
      :timeout="8000"
      v-model="subscriptionAlert"
    >
      <div class="px-6 py-4 lg:w-144 w-112 flex lg:text-base text-sm justify-between">
        <div class="pr-6">
          {{subscriptionMsg}}
        </div>
        <button
          @click="subscriptionAlert = false"
          class="text-pink-500 focus:outline-none font-medium text-sm"
        >CLOSE</button>
      </div>
    </Alert>
  </div>
</template>

<script>
import Vue from 'vue';
import RequestBrochureModal from '@/components/RequestBrochureModal.vue';
import Alert from '@/components/Alert.vue';
import memberGoto from '@/util/memberGoto';

export default Vue.extend({
  methods: {
    memberGoto,
    showRequestModal(seminar) {
      this.brochureRequest = `Request for ${seminar.title} brochure`;
      this.requestModalVisible = true;
    },
    requestSent() {
      this.requestModalVisible = false;
      this.subscriptionMsg = 'Your request is sent successfully! Our consultants will contact you soon.';
      this.subscriptionAlert = true;
    },
  },

  components: {
    RequestBrochureModal,
    Alert,
  },

  data() {
    return {
      subscriptionAlert: false,
      subscriptionMsg: '',
      brochureRequest: '',
      requestModalVisible: false,
      activityCalendarURL: '/api/file/MRC_Activity Calendar 2025.pdf',
      symposium: Object.freeze({
        title: '2-day Symposium - Total Rewards, HR & Predictive Analytics',
        date: 'September 2025',
        datelocation: {
          loc1: 'Singapore Physical/Online Event: 22 Sep- 23 Sep',
          loc2: 'Hong Kong Physical Event: 25 Sep- 26 Sep',
        },
        keytakeaway:
          'A practical 2-day symposium offering APAC market and data insights at your fingertips! Hot topics & insights supported by MRC’S PAY DATA on local, expat, hybrid, executive, sales & employee compensation, benefits, predictive analytics and HR practices, plus pay trends by country across APAC.',
        skill: '',
        suitablefor: 'C&B/ HRD/ Analytics/ Mobility',
        brochure: '/api/file/MRC_Symposium2025.pdf',
        canDownload: false,
      }),
      seminars: Object.freeze([
        {
          title: 'DESIGNING SALES COMMISSION, SALES BONUS & SALES INCENTIVE',
          date: 'February 2025',
          datelocation: {
            loc1: 'Online Workshop: 17 Feb - 18 Feb',
          },
          keytakeaway:
            'Use of sales commission, sales bonus, and/or sales incentive and design principles, simulation and assessment of plan effectiveness.',
          skill: 'Design techniques, simulations, calculation formulae & practical applications',
          suitablefor: 'C&B, HR & Sales',
          brochure:
            '/api/file/MRC_Designing_Sales_Commission_Sales_Bonus_Sales_Incentive_Online_Seminar_2025.pdf',
          canDownload: true,
        },
        {
          title: 'DESIGNING REMUNERATION & BONUS: PAY FOR POSITION, PERSON & PERFORMANCE',
          date: 'April 2025',
          datelocation: {
            loc1: 'Online Workshop: 09 Apr - 11 Apr',
          },
          keytakeaway:
            'Different approaches in use and design of salary, performance appraisal and awarding bonus, and determining salary increase and individual pay.',
          skill: 'Design techniques, calculation formulae, practical applications & costing',
          suitablefor: 'C&B & HR',
          brochure:
            '/api/file/MRC_Designing_Remuneration_Seminar_2025.pdf',
          canDownload: true,
        },
        {
          title: 'DESIGNING STOCK AND PHANTOM STOCK PLANS',
          date: 'May 2025 ',
          datelocation: {
            loc1: 'Online Workshop: 16 May',
          },
          keytakeaway:
            'Use of various types of stock and stock-alike awards, when to use them, how to design and distribute, value and cost them, including pre-IPO award design.',
          skill: 'LTI choices, applications, design techniques & calculation formulae',
          suitablefor: 'C&B, HR & Sales',
          brochure:
            '/api/file/.pdf',
          canDownload: false,
        },
        {
          title: 'POWER BI: REWARDS & PEOPLE ANALYTICS FOR HR/C&B',
          date: 'June 2025 ',
          datelocation: {
            loc1: 'Online Workshop: 19 Jun - 20 Jun',
          },
          keytakeaway:
            'Data analytics, especially HR dashboards, should prescribe action, not merely summarize trends. To make something happen from data, the key is to know the criteria to finding patterns in data. Learn Power BI techniques catered specifically towards HR and Rewards professionals.',
          skill: 'Power BI',
          suitablefor: 'All HR professionals',
          brochure:
            '/api/file/.pdf',
          canDownload: false,
        },
        {
          title: 'DATA SCIENCE & ANALYTICS IN HR/C&B',
          date: 'July 2025',
          datelocation: {
            loc1: 'Online Workshop: 24 Jul - 25 Jul',

          },
          keytakeaway:
            'Be guided with a step-by-step training for essential analytic skills. Gain practical knowledge on Excel-centric HR/C&B skills and knowledge to increase your productivity!',
          skill: 'Excel skills required for data manipulation, regression techniques, developing salary ranges and measuring pay competitiveness',
          suitablefor: 'C&B / HR',
          brochure: '/api/file/MRC Data Science & Analytics in HR & C&B_2025.pdf',
          canDownload: true,
        },
        {
          title: 'DESIGNING MOBILITY PACKAGE',
          date: 'November 2025 ',
          datelocation: {
            loc1: 'Online Workshop: 21 Nov',
          },
          keytakeaway:
            'Be equipped with knowledge, insights, and skills required to construct different types of relocation packages. Gain an in-depth understanding of various relocation package considerations and localization approaches. Participants will also learn how to develop a global/regional relocation policy.',
          skill: 'Relocation package types, localization, policy considerations & development',
          suitablefor: 'C&B, HR, Mobility',
          brochure:
            '/api/file/.pdf',
          canDownload: false,
        },
      ]),
    };
  },
});
</script>

<style scoped>
.banner {
  background-image: url('../assets/img/seminar_banner.jpg');
}
.symposium {
  background-image: url('../assets/img/seminar_symposium.jpg');
}
.symposium_body {
  background-image: url('../assets/img/seminar_symposium_body.jpg');
}
.seminar {
  background-image: url('../assets/img/seminar_seminar.jpg');
}
.seminar_header {
  background-image: url('../assets/img/seminar_seminar_header.jpg');
}
.seminar_body {
  background-image: url('../assets/img/seminar_seminar_body.jpg');
}
</style>
